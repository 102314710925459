import "./darkheader.scss"
import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Button, Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "gatsby-plugin-react-i18next"
import logoBlueEn from "../../images/icons/i-am-bts-blue-en.svg"
import logoBlueEs from "../../images/icons/i-am-bts-blue-es.svg"

const DarkHeader = ({
    children,
    title1,
    title2,
    sectionClassName,
    onAccept,
    callButton = <Button>Join Now</Button>,
}) => {
    const { t, i18n } = useTranslation("common", { keyPrefix: "joinBts" })
    const logo = { en: logoBlueEn, es: logoBlueEs }
    return (
        <section className={(sectionClassName ? sectionClassName + " " : "") + "section-dark-header"}>
            <Container fluid="xl">
                <Row>
                    <Col md={3} lg={6}>

                    </Col>
                    <Col className="wrapper-text" md={9} lg={6}>
                        <h1>{title1}</h1>
                        <h2>{title2}</h2>
                        {children}
                        {callButton ? React.cloneElement(callButton, { variant: "success", onClick: onAccept }) : ""}
                        <Link className="goto-search-arrow d-none d-lg-block" to="#goContent">
                            <span className="icon-arrow-down arw-white"> </span>
                        </Link>
                    </Col>
                </Row>
                <div className="bts-logo-wrapper">
                    <img
                        className="bts-logo d-none d-lg-inline-block d-xl-none"
                        width={83}
                        alt="Who we are"
                        src={logo[i18n.language.toLocaleLowerCase() || "en"]}
                    />
                    <img
                        className="bts-logo d-none d-xl-inline-block"
                        width={170}
                        alt="Who we are"
                        src={logo[i18n.language.toLocaleLowerCase() || "en"]}
                    />
                    <h3 className="d-none d-lg-inline-block">{t("Join the BTS Family")}</h3>
                </div>
            </Container>
        </section>
    )
}

DarkHeader.propTypes = {
    title1: PropTypes.string,
    title2: PropTypes.string,
    paragraph: PropTypes.string
}

DarkHeader.defaultProps = {
    title1: "Page title",
    title2: "Motivational Phrase",
    paragraph: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
};

export default DarkHeader
